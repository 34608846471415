import axios from "axios";

export const apiGetHosePositions = (equipmentTypeName) => {
  return axios
    .get(`/api/Jobbing/hose/positions/${equipmentTypeName}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.positions) {
            result.data.positions.map((item) => {
              item.text = item.name;
              item.value = item.id;
            });
            return result.data;
          }
        }
      }
      return result.data;
    });
};

export const apiGetProduct = (productName) => {
  const data = {
    SearchParam: productName ? productName : null,
  };
  return axios.get(`/api/Products/`, { params: data }).then((result) => {
    if (result) {
      if (result.data) {
        result.data.records[0].stockImage1 = result.data.records[0].image128;
        result.data.records[0].stockDescription = result.data.records[0].name;
        return result.data.records[0];
      }
    }
    return false;
  });
};

export const apiUploadHoseTestingCertificate = async (
  data,
  jobId,
  hoseMakerSeqId,
  hoseTagSeqId
) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(
      `/api/Jobbing/upload-job-hose-test-certificate?jobId=${jobId}&hoseMakerSeqId=${hoseMakerSeqId}&hoseTagSeqId=${hoseTagSeqId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        console.log(result.data);
        return result.data;
      }
      return false;
    });
};

export const apiDeleteHoseTestingCertificate = async (
  jobId,
  hoseMakerSeqId,
  hoseTagSeqId
) => {
  return axios
    .delete(
      `/api/Jobbing/delete-hose-test-certificate?jobId=${jobId}&hoseMakerSeqId=${hoseMakerSeqId}&hoseTagSeqId=${hoseTagSeqId}`
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiUploadHoseMakerImage = async (imageData, job_id, seq_id) => {
  const formData = new FormData();

  formData.append("file", imageData);
  return axios
    .post(
      `/api/Jobbing/upload-hosemaker-image?jobId=${job_id}&hoseMakerSeqId=${seq_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    });
};

export const apiUploadQuotingHoseMakerImage = async (
  imageData,
  quoteId,
  seq_id
) => {
  const formData = new FormData();

  formData.append("file", imageData);
  return axios
    .post(
      `/api/Quoting/upload-quote-hosemaker-image?quoteId=${quoteId}&hoseMakerSeqId=${seq_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    });
};

export const apiRemoveHoseMakerImage = async (jobId, imageId) => {
  return axios
    .delete(
      `/api/Jobbing/delete-hosemaker-image?jobId=${jobId}&imageId=${imageId}`
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};
