import React, { useEffect, useState } from "react";
import { Grid, Loader } from "semantic-ui-react";
import { FadeIn } from "react-animated-components";
import { apiAcceptQuote } from "../Quoting/quoting.api";
import boaLogo from "../../assets/images/boa_icon.png";

const AcceptQuote = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(false);
  const [invalidQuoteDetails, setInvalidQuoteDetails] = useState(false);

  useEffect(() => {
    console.log(location);
    const quoteId = new URLSearchParams(location.search).get("quoteId");
    const createdDate = new URLSearchParams(location.search).get("createdDate");
    if (!quoteId || !createdDate) {
      setInvalidQuoteDetails(true);
      setIsLoading(false);
    } else {
      const delayDebounceFn = setTimeout(() => {
        onMoveQuoteToJobbing(quoteId, createdDate);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, []);

  const onMoveQuoteToJobbing = async (quoteId, createdDate) => {
    const result = await apiAcceptQuote(quoteId, createdDate);
    if (result) {
      if (result.status === 204) {
        setData(result);
        setIsSuccess(true);
        setIsLoading(false);
      } else {
        setData(result);
        setIsSuccess(false);
        setIsLoading(false);
      }
    } else {
      setIsSuccess(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          marginTop: isLoading ? 100 : 0,
          marginLeft: "auto",
          marginRight: "auto",
          height: 550,
        }}
      >
        {isLoading ? (
          <Loader active className="workaround" size="large" inline="centered">
            Loading, please wait...
          </Loader>
        ) : isSuccess ? (
          <FadeIn fade durationMs={600}>
            <Grid>
              <Grid.Row verticalAlign="middle">
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: -10,
                    marginBottom: 50,
                  }}
                >
                  <img src={boaLogo} style={{ width: 150, height: 73.56 }} />
                </div>
                <div
                  style={{
                    margin: "0px auto",
                    minHeight: "220px",
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  <h1>Success!</h1>
                  <h3>Quote has been accepted.</h3>
                  {!window.localStorage.getItem("user") && (
                    <>
                      <p>Login to BOAhub for more details.</p>
                      <br />
                    </>
                  )}
                  <div className="home-page-link" style={{ marginTop: 100 }}>
                    <a href="/">{window.localStorage.getItem("user") ? 'Back to Home' : 'Login to BOAhub'}</a>
                  </div>
                </div>
              </Grid.Row>
            </Grid>
          </FadeIn>
        ) : (
          <FadeIn fade durationMs={600}>
            <Grid>
              {invalidQuoteDetails && (
                <Grid.Row verticalAlign="middle">
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: -10,
                      marginBottom: 50,
                    }}
                  >
                    <img src={boaLogo} style={{ width: 150, height: 73.56 }} />
                  </div>
                  <div
                    style={{
                      margin: "0px auto",
                      minHeight: "220px",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    <h1>Error</h1>
                    <h3>Unable to access this page.</h3>
                    <br />
                    <div className="home-page-link" style={{ marginTop: 100 }}>
                      <a href="/">{window.localStorage.getItem("user") ? 'Back to Home' : 'Login to BOAhub'}</a>
                    </div>
                  </div>
                </Grid.Row>
              )}
              {!invalidQuoteDetails && (
                <Grid.Row verticalAlign="middle">
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: -10,
                      marginBottom: 50,
                    }}
                  >
                    <img src={boaLogo} style={{ width: 150, height: 73.56 }} />
                  </div>
                  <div
                    style={{
                      margin: "0px auto",
                      minHeight: "220px",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    <h1>Error</h1>
                    <h3>Unable to accept the quote.</h3>
                    <p>{data.description}</p>
                    <br />
                    <div className="home-page-link" style={{ marginTop: 100 }}>
                      <a href="/">{window.localStorage.getItem("user") ? 'Back to Home' : 'Login to BOAhub'}</a>
                    </div>
                  </div>
                </Grid.Row>
              )}
            </Grid>
          </FadeIn>
        )}
      </div>
    </>
  );
};

export default AcceptQuote;
