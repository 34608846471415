import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Menu, Modal, Icon, Grid, Step } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as appReducer from "../../containers/App/app.reducer";
import * as cartReducer from "../../containers/Cart/cart.reducer";
import logoImage from "../../assets/images/logo.png";
import _ from "lodash";
import polyglot from "../../i18n";
import "../../index.css";
import CommonMenu from "../CommonMenu";
import BurgerMenu from "../UserMobileHeader";
import jobbing from "../../assets/images/pages/jobbing.png";
import quoting from "../../assets/images/pages/quoting.png";

const images = [quoting, jobbing];

class UserHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      activeItem: "home",
      openMenu: false,
      isFacility: false,
      isModalOpen: false,
      cartCount: localStorage.getItem("cartCount"),
      isJobbingOpen: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    let { location } = history;
    let { pathname } = location;
    // this.loadCart();
    pathname = pathname.replace(/\//g, "");
    this.setState({ activeItem: pathname });

    const currentCountryCode = localStorage.getItem("countryCode");
    this.setState({ countryCode: currentCountryCode });

    const accountTypeData = localStorage.getItem("accountType");
    this.setState({ accountType: Number(accountTypeData) });

    const hasJobbing = localStorage.getItem("accessJobbing");
    if (hasJobbing === "true") {
      this.setState({ hasJobbingAccess: true });
    } else {
      this.setState({ hasJobbingAccess: false });
    }

    window.addEventListener("localStorageUpdated", this.handleStorageUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("localStorageUpdated", this.handleStorageUpdate);
  }

  componentDidUpdate = () => {
    const { account } = this.props.user;
    if (account) {
      const { showFacilities } = account;
      if (this.state.isFacility === showFacilities) {
        return;
      } else {
        this.setState({ isFacility: showFacilities });
      }
    }
  };

  handleStorageUpdate = () => {
    // Update the state with the new localStorage value
    this.setState({
      cartCount: localStorage.getItem("cartCount"),
    });
  };

  updateLocalStorage = () => {
    // Update localStorage and dispatch the custom event
    localStorage.setItem("cartCount", "newData");
    window.dispatchEvent(new Event("localStorageUpdated"));
  };

  // loadCart = async () => {
  //   if (window.location.pathname !== "/cart") await this.props.actionLoadCart();
  // };

  handleItemClick = (e, lintData) => {
    e.preventDefault();
    const { value } = lintData;
    this.setState({ activeItem: value, openMenu: false });
    this.props.history.push("/" + value);
  };
  goHome = (e) => {
    e.preventDefault();
    this.props.history.push("/");
  };

  getCartCount = () => {
    let quantity = 0;
    _.forEach(this.props.cart, (item) => {
      //const { pack } = item;
      quantity += 1;
      // if (pack === "Metre") {
      //   quantity += 1;
      // } else {
      //   quantity += Number(item.quantity);
      // }
    });
    return quantity;
  };

  logoutUser = () => {
    const { user } = this.props;
    this.props.logout(user);
  };

  render() {
    const {
      activeItem,
      openMenu,
      isModalOpen,
      countryCode,
      hasJobbingAccess,
      accountType,
      isJobbingOpen,
    } = this.state;

    const { firstName, adminSessionId, lastName } =
      Object.entries(this.props.user).length === 1
        ? this.props.user.user
        : this.props.user;

    let trigger = (
      <span>
        <span className="title">
          Hello, <span className="user-name">{firstName}</span>
        </span>
        &nbsp;&nbsp;
        <Icon name="user" />
      </span>
    );

    const isAdminLoggedIn = window.localStorage.getItem("isAdminLogin");

    return (
      <header className="main-menu">
        <Modal
          onClose={() => this.setState({ isJobbingOpen: false })}
          closeIcon
          closeOnDimmerClick={true}
          size="tiny"
          open={isJobbingOpen}
          style={{ marginTop: -150 }}
        >
          <Modal.Header>JOB MANAGEMENT</Modal.Header>
          <Modal.Content>
            <Grid style={{ padding: 30 }}>
              {localStorage.getItem("isBoahubPremium") !== "true" && (
                <Grid.Column width={4}></Grid.Column>
              )}
              {localStorage.getItem("isBoahubPremium") === "true" && (
                <Grid.Column
                  width={8}
                  className="card-body"
                  style={{ textAlign: "center", position: "relative" }}
                >
                  <Step.Group
                    style={{
                      width: "100%",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      border: "1.5px solid rgb(102, 204, 35)",
                    }}
                  >
                    <Step
                      onClick={(e) => {
                        e.preventDefault();
                        if (e.ctrlKey) {
                          e.stopPropagation();
                          window.open(`/quoting`);
                          return;
                        }
                        this.props.history.push("/" + "quoting");
                        this.setState({ isJobbingOpen: false });
                      }}
                    >
                      <img
                        src={images[0]}
                        style={{
                          height: 70,
                          width: "auto",
                        }}
                      />
                    </Step>
                  </Step.Group>
                  <div
                    style={{
                      position: "absolute",
                      bottom: -5,
                      width: "100%",
                      paddingRight: 32,
                    }}
                  >
                    <span style={{ fontSize: 20, color: "rgb(102, 204, 35)" }}>
                      Quoting
                    </span>
                  </div>
                </Grid.Column>
              )}
              <Grid.Column
                width={8}
                className="card-body"
                style={{ textAlign: "center", position: "relative" }}
              >
                <Step.Group
                  style={{
                    width: "100%",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    border: "1.5px solid rgb(102, 204, 35)",
                  }}
                >
                  <Step
                    onClick={(e) => {
                      e.preventDefault();
                      if (e.ctrlKey) {
                        e.stopPropagation();
                        window.open(`/jobbing`);
                        return;
                      }
                      this.props.history.push("/" + "jobbing");
                      this.setState({ isJobbingOpen: false });
                    }}
                  >
                    <img
                      src={images[1]}
                      style={{
                        height: 70,
                        width: "auto",
                      }}
                    />
                  </Step>
                </Step.Group>
                <div
                  style={{
                    position: "absolute",
                    bottom: -5,
                    width: "100%",
                    paddingRight: 32,
                  }}
                >
                  <span style={{ fontSize: 20, color: "rgb(102, 204, 35)" }}>
                    Jobbing
                  </span>
                </div>
              </Grid.Column>
              {localStorage.getItem("isBoahubPremium") !== "true" && (
                <Grid.Column width={4}></Grid.Column>
              )}
            </Grid>
          </Modal.Content>
        </Modal>
        <Modal
          dimmer="blurring"
          size="tiny"
          open={isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
        >
          <Modal.Header>Logout</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to logout?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ isModalOpen: false })}>
              No
            </Button>
            <Button positive onClick={() => this.logoutUser()}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        {/* TODO: Phase 2 section  pass isFacility variable to show it*/}
        {window.location.href.includes("callback") ? null : (
          <BurgerMenu
            activeItem={activeItem}
            getCartCount={this.getCartCount}
            handleItemClick={this.handleItemClick}
            openMenu={openMenu}
            isFacility={false}
            logout={this.logoutUser}
          />
        )}
        <Menu className="profile-menu">
          <Menu.Item>
            <a href="/">
              <img alt="BOA" src={logoImage} className="boa-logo-menu" />{" "}
            </a>
          </Menu.Item>
          {isAdminLoggedIn && adminSessionId && (
            <span
              style={{ color: "yellow", margin: "auto", fontSize: "1.4em" }}
            >
              Admin Logged In as: "{firstName} {lastName}"
            </span>
          )}
          {/* <Menu.Item
            name="Cart"
            value="cart"
            active={activeItem === "cart"}
            onClick={this.handleItemClick}
            className="user-menu-mobile cart_Link"
          >
            <Icon name="shopping cart" />
            <Label color="green" floating>
              {this.getCartCount()}
            </Label>
          </Menu.Item> */}
          <Menu.Menu
            position="right"
            className="user-menu"
            style={{ position: "absolute", right: 0, top: -45, zIndex: 2001 }}
          >
            <Menu.Item
              onClick={() => {
                localStorage.setItem("countryCode", "NZ");
                window.location.reload();
              }}
              as="a"
              className={countryCode != "AU" ? "top-menu-active" : "top-menu"}
            >
              BOA New Zealand
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                localStorage.setItem("countryCode", "AU");
                window.location.reload();
              }}
              as="a"
              className={countryCode == "AU" ? "top-menu-active" : "top-menu"}
            >
              BOA Australia
            </Menu.Item>
            <Menu.Item
              as="div"
              onClick={() => this.setState({ isModalOpen: true })}
              style={{
                borderLeft: "1px solid #4f5152",
              }}
            >
              <a
                onClick={() => this.setState({ isModalOpen: true })}
                className="top-menu-logout"
              >
                Logout
              </a>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu
            position="right"
            className="user-menu"
            style={{ position: "absolute", right: 0, top: -25, zIndex: 2000 }}
          >
            <Menu.Item
              name="Contact"
              value="contact"
              as="a"
              href={
                countryCode != "AU" ? "tel:0800 20 20 20" : "tel:1800 40 30 40"
              }
              className="contact"
              style={{
                color: "#66cc23",
              }}
            >
              {countryCode != "AU" ? "0800 20 20 20" : "1800 40 30 40"}
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right" className="user-menu" color={"#66cc23"}>
            {/* <Menu.Item
              name="Chat"
              value="chat"
              active={activeItem === "chat"}
              onClick={this.handleItemClick}
              as="a"
              href="/chat"
            >
              <Icon name="discussions" color="green" className="chat_bubble" />
            </Menu.Item> */}
            <Menu.Item
              className="header-menu-item"
              name="Home"
              value="home"
              active={activeItem === "home"}
              onClick={this.handleItemClick}
              as="a"
              href="/home"
            >
              <Icon name="home" />
              Home
            </Menu.Item>
            {/* {accountType === 3 ? null : (
              <Menu.Item
                className="header-menu-item"
                name="Products"
                value="search"
                active={activeItem === "search"}
                onClick={this.handleItemClick}
                as="a"
                href="/search"
              >
                <Icon name="search" />
                Products
              </Menu.Item>
            )} */}
            {accountType === 3 ? null : (
              <Menu.Item
                className="header-menu-item"
                name="Cart"
                value="cart"
                active={activeItem === "cart"}
                onClick={this.handleItemClick}
                as="a"
                href="/cart"
              >
                <Icon name="shopping cart" />
                <div
                  style={{
                    height: 18,
                    width: 18,
                    borderRadius: 15,
                    backgroundColor: "#ee4035",
                    marginLeft: -18,
                    marginRight: 5,
                    marginTop: -25,
                    textAlign: "center",
                    zIndex: 2000,
                    lineHeight: 0,
                    paddingTop: 7,
                  }}
                >
                  <span style={{ fontSize: 11 }} className="cart-count">
                    {this.state.cartCount}
                  </span>
                </div>
                Cart
              </Menu.Item>
            )}
            {/* {accountType === 3 ? null : (
              <Popup
                content="Coming Soon!"
                position="bottom center"
                trigger={
                  <Menu.Item
                    className="header-menu-item"
                    name="Orders"
                    value="orders"
                    as="a"
                  >
                    <Icon name="file text" />
                    Orders
                  </Menu.Item>
                }
              />
            )} */}
            {hasJobbingAccess && (
              <>
                {localStorage.getItem("isBoahubPremium") === "true" ? (
                  <Menu.Item
                    className="header-menu-item"
                    name="Jobbing"
                    value="jobbing"
                    active={activeItem === "jobbing"}
                    onClick={() => this.setState({ isJobbingOpen: true })}
                  >
                    <Icon name="configure" />
                    Job Management
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    className="header-menu-item"
                    name="Jobbing"
                    value="jobbing"
                    active={activeItem === "jobbing"}
                    onClick={this.handleItemClick}
                  >
                    <Icon name="configure" />
                    Jobbing
                  </Menu.Item>
                )}
              </>
            )}
            <Menu.Item
              className="header-menu-item"
              name="Profile"
              value="settings"
              active={activeItem === "settings"}
              onClick={this.handleItemClick}
              as="a"
              href="/settings"
            >
              <Icon name="setting" />
              Settings
            </Menu.Item>
            <Menu.Item
              className="header-menu-item"
              name="Profile"
              value="profile"
              active={activeItem === "profile"}
              onClick={this.handleItemClick}
              as="a"
              href="/profile"
            >
              <Icon name="user" />
              Profile
            </Menu.Item>
            <Menu.Item
              className="header-menu-item"
              name="Email"
              value="email"
              as="a"
              href="mailto:sales@boahydraulics.com"
            >
              sales@boahydraulics.com
            </Menu.Item>
            {/* <Dropdown trigger={trigger} pointing className="link item">
              <Dropdown.Menu>
                <Dropdown.Item
                  value="profile"
                  active={activeItem === "profile"}
                  onClick={this.handleItemClick}
                  href="/profile"
                  text="Profile"
                />
                <Dropdown.Item
                  value="orderHistory"
                  active={activeItem === "orderHistory"}
                  onClick={this.handleItemClick}
                  href="/orderHistory"
                  text="Order history"
                /> 
                <Dropdown.Item onClick={() => this.logoutUser()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </Menu.Menu>
        </Menu>
        <Menu secondary stackable className="sub-menu">
          {/* <Menu.Menu position="right" className="user-menu-mobile">
            <Dropdown trigger={trigger} pointing className="link item">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to={"/profile"}> Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to={"/orderHistory"}> Order history</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.logoutUser()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu> */}
          {/* TODO: Phase 2 section  pass isFacility variable to show it*/}
          {/* <CommonMenu
            activeItem={activeItem}
            getCartCount={this.getCartCount}
            handleItemClick={this.handleItemClick}
            visibility="user-menu"
            isFacility={this.state.isFacility}
          /> */}
        </Menu>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cart: state.cart,
});

const reducer = { ...appReducer, ...cartReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
