import _ from "lodash";
import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

const CustomerSearch = ({
  customerList,
  onSelectCustomer,
  companyName,
  style,
}) => {
  const [assetLoading, setAssetLoading] = useState(false);

  function handleChange(value) {
    const customer = customerList.find((item) => item.companyName === value);
    if (customer) handleResultSelect(customer);
  }

  const handleResultSelect = (result) => {
    const userName = localStorage.getItem("user");
    result.userName = userName;
    setAssetLoading(true);
    const data = {
      id: result.id,
      companyName: result.companyName,
      userName: result.userName,
      discountPercent: result.discountPercent,
    };
    onSelectCustomer(data);
    setTimeout(function () {
      setAssetLoading(false);
    }, 2000);
  };

  return (
    <Dropdown
      placeholder={assetLoading ? "" : "Search Customer"}
      search
      selection
      options={customerList}
      value={companyName}
      style={style}
      onChange={(e, data) => handleChange(data.value)}
    />
  );
};

export default CustomerSearch;
