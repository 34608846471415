import React from "react";
import NumericInput from "react-numeric-input";
import PropTypes from "prop-types";
import { Card, Grid, Icon, Input, Popup } from "semantic-ui-react";
import { DiscountInputChecker } from "../../utils/utils";

const NumericInputDiscount = ({ value, onBlurHandler }) => {
  return (
    <Grid style={{ padding: 0, margin: 0 }}>
      <Grid.Column width={16} style={{ padding: 0, margin: 0 }}>
        <Input
          type="text"
          label={{
            basic: true,
            content: "%",
          }}
          labelPosition="right"
          defaultValue={value}
          onBlur={onBlurHandler}
          onKeyDown={DiscountInputChecker}
          input={{
            style: {
              textAlign: "left",
            },
          }}
        />
      </Grid.Column>
    </Grid>
  );
};

NumericInputDiscount.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChangeHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
};

export default NumericInputDiscount;
